import { Component, OnChanges, Input, OnInit, ComponentRef, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { KeyMap } from "../../../../models/shared";

import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { SourceSourcesNameColumnComponent } from "./source-sources-name-column/source-sources-name-column.component";
import { ZxSourceSourcesActionButtonsComponent } from "./zx-source-sources-action-buttons/zx-source-sources-action-buttons.component";
import { Source, FailoverSource } from "../../../../models/shared";

@Component({
    selector: "app-source-sources",
    templateUrl: "./source-sources.component.html"
})
export class SourceSourcesComponent implements OnInit, OnChanges {
    @Input() source: Source;
    @Input() autoRows? = true;

    sources: FailoverSource[] = [];
    currentSortDirection: string;
    private translate = inject(TranslateService);

    private sourcesBS$ = new BehaviorSubject<FailoverSource[]>([]);

    tableColumnsSchema: TableSchema<KeyMap<FailoverSource>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 160,
            sticky: 1,
            component: SourceSourcesNameColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<SourceSourcesNameColumnComponent>, row: FailoverSource) => {
                const componentInstance = compRef.instance;
                componentInstance.source = row;
            },
            sortBy: row => row.source.name
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<FailoverSource>>(
                row =>
                    row.locked_source
                        ? this.translate.instant("LOCKED")
                        : row.source.statusFromCompoundComponent?.input_status
                        ? row.source.statusFromCompoundComponent?.input_status
                        : "-",
                row =>
                    row.locked_source
                        ? this.translate.instant("LOCKED")
                        : row.source.statusFromCompoundComponent?.input_status
                        ? row.source.statusFromCompoundComponent?.input_status
                        : "-",
                () => true
            ),
            sortBy: row =>
                row.locked_source
                    ? this.translate.instant("LOCKED")
                    : row.source.statusFromCompoundComponent?.input_status
                    ? row.source.statusFromCompoundComponent?.input_status
                    : "-"
        },
        {
            header: this.translate.instant("OFFSET_MS"),
            columnDef: "offset",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<FailoverSource>>(
                row =>
                    row.source.statusFromCompoundComponent?.packet_latency_ms === -1
                        ? this.translate.instant("OUT_OF_SYNC")
                        : row.source.statusFromCompoundComponent?.packet_latency_ms
                        ? row.source.statusFromCompoundComponent?.packet_latency_ms
                        : "-",
                row =>
                    row.source.statusFromCompoundComponent?.packet_latency_ms === -1
                        ? this.translate.instant("OUT_OF_SYNC")
                        : row.source.statusFromCompoundComponent?.packet_latency_ms
                        ? row.source.statusFromCompoundComponent?.packet_latency_ms
                        : "-",
                () => true
            ),
            sortBy: row =>
                row.source.statusFromCompoundComponent?.packet_latency_ms === -1
                    ? this.translate.instant("OUT_OF_SYNC")
                    : row.source.statusFromCompoundComponent?.packet_latency_ms
                    ? row.source.statusFromCompoundComponent?.packet_latency_ms
                    : "-"
        },
        {
            header: this.translate.instant("ACTIVE"),
            columnDef: "is_active",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<FailoverSource>>(
                row => (row.is_active ? "Yes" : "No"),
                row => (row.is_active ? "Yes" : "No"),
                () => true
            ),
            sortBy: (row: KeyMap<FailoverSource>) => (row.is_active ? "Yes" : "No"),
            textValue: (row: KeyMap<FailoverSource>) => (row.is_active ? "Yes" : "No")
        },
        {
            header: this.translate.instant("PACKETS"),
            columnDef: "packets",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<FailoverSource>>(
                row =>
                    row.source.statusFromCompoundComponent?.packets
                        ? row.source.statusFromCompoundComponent?.packets.toString()
                        : "-",
                row =>
                    row.source.statusFromCompoundComponent?.packets
                        ? row.source.statusFromCompoundComponent?.packets.toString()
                        : "-",
                () => true
            ),
            sortBy: row =>
                row.source.statusFromCompoundComponent?.packets
                    ? row.source.statusFromCompoundComponent?.packets.toString()
                    : "-"
        },
        {
            header: this.translate.instant("PRIORITY"),
            columnDef: "priority",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<FailoverSource>>(
                row =>
                    row.priority === 0
                        ? this.translate.instant("SLATE")
                        : row.priority === 1
                        ? this.translate.instant("SECONDARY")
                        : this.translate.instant("PREFERRED"),
                row =>
                    row.priority === 0
                        ? this.translate.instant("SLATE")
                        : row.priority === 1
                        ? this.translate.instant("SECONDARY")
                        : this.translate.instant("PREFERRED"),
                () => true
            ),
            sortBy: row =>
                row.priority === 0
                    ? this.translate.instant("SLATE")
                    : row.priority === 1
                    ? this.translate.instant("SECONDARY")
                    : this.translate.instant("PREFERRED")
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 70,
            visible: true,
            align: "right",
            stickyToLast: true,
            component: ZxSourceSourcesActionButtonsComponent,
            assignComponentsInputs: (
                compRef: ComponentRef<ZxSourceSourcesActionButtonsComponent>,
                row: FailoverSource
            ) => {
                const componentInstance = compRef.instance;
                componentInstance.source = row;
            }
        }
    ];

    ngOnInit(): void {
        this.prepTableData();
    }

    async ngOnChanges() {
        this.prepTableData();
    }

    get sources$() {
        return this.sourcesBS$.asObservable();
    }

    private prepTableData() {
        if (this.source?.failoverSources) this.sources = this.source.failoverSources;
        else this.sources = [];

        if (this.sources) {
            this.sourcesBS$.next(this.sources);
        }
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }
}
