import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, ComponentRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, BehaviorSubject, interval } from "rxjs";
import * as _ from "lodash";

import { SortDirection } from "../../../directives/sortable.directive";

import { Constants } from "../../../constants/constants";
import { GridsService } from "../grids.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";

import { Grid } from "../grid";
import { ZixiObject, User, KeyMap, MediaConnectSource, Source, ZixiPlus } from "../../../models/shared";
import { StatePipe } from "src/app/pipes/state.pipe";
import { Cluster } from "../../clusters/cluster";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { ExportExcelService } from "src/app/services/export-excel.service";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { take } from "rxjs/operators";

import { TourService } from "ngx-ui-tour-md-menu";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { GridObjectsColumnComponent } from "./grid-objects-column/grid-objects-column.component";
import { assignComponentsGridObjectsAdapter } from "./grid-objects-column/grid-objects-column.table-adapter";
import { DecimalPipe, Location } from "@angular/common";
import { ZxNumericColComponent } from "src/app/components/shared/zx-numeric-col/zx-numeric-col.component";
import { ZxTr101ColSourceComponent } from "src/app/components/shared/zx-tr101-col-source/zx-tr101-col-source.component";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { VersionPipe } from "src/app/pipes/version.pipe";
import { GridChannelColumnComponent } from "./grid-channel-column/grid-channel-column.component";
import { assignComponentsGridChannelAdapter } from "./grid-channel-column/grid-channel-column.table-adapter";
import { GridClusterColumnComponent } from "./grid-cluster-column/grid-cluster-column.component";
import { assignComponentsGridClusterAdapter } from "./grid-cluster-column/grid-cluster-column.table-adapter";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { SourceThumbnailComponent } from "../../sources/source/source-thumbnail/source-thumbnail.component";
import { ZxTagsListComponent } from "src/app/components/shared/zx-tags-list/zx-tags-list.component";
import { TourSteps } from "src/app/constants/tour-steps";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";
import { TargetsService } from "../../targets/targets.service";
import { TargetObjectType } from "../../channels/channel";
import { SourcesService } from "../../sources/sources.service";
import { TargetThumbnailComponent } from "../../targets/target/target-thumbnail/target-thumbnail.component";
import { ThumbnailWrapperComponent } from "../../sources/source/thumbnail-wrapper/thumbnail-wrapper.component";

interface State {
    page: number;
    pageSize: number;
    startNum: number;
    endNum: number;
    sortColumn: string;
    sortDirection: SortDirection;
    searchTerm: string;
}

export class GridObject extends ZixiPlus {
    inputCluster?: Cluster;
    processingCluster?: Cluster;
    cluster?: string;
}

@Component({
    selector: "app-grid-detail",
    templateUrl: "./grid-detail.component.html",
    styleUrls: ["../grids.component.scss"]
})
export class GridDetailComponent implements OnInit, OnDestroy {
    user: User;

    id: number;
    grid: Grid;
    loadingDetails = true;
    refreshing = false;

    isExpanded = false;
    fitToScreen = false;
    showInfoBtm = false;
    selectMode = true;

    displayAlerts = false;
    displayAudio = false;
    displayTime = false;

    showTopBar = true;
    showBottomBar = true;
    viewOption = "grid";
    fullyLoaded = false;

    firstLoad = true;
    cyclePagination: boolean;
    cyclePaginationInterval: number;

    gridCols: number;
    gridRows: number;
    tileHeight: number;
    gridHeight: number;

    currentObjectIDs: Record<string, number[]>;
    currentTableObjectIDs: Record<string, number[]>;
    currentObjects: ZixiObject[];
    filteredObjects: ZixiObject[];

    selectedObjects: ZixiObject[] = [];
    selectedRows: GridObject[] = [];

    @ViewChild("gridDetail", { static: true }) gridDetail: ElementRef;
    @ViewChild("gridContent", { static: true }) gridContent: ElementRef;

    private gridSubscription: Subscription;
    private gridRefreshSubscription: Subscription;
    private gridCycleSubscription: Subscription;

    private currentPageObjectsMatrixBS$ = new BehaviorSubject<ZixiObject[][]>([]);
    private totalBS$ = new BehaviorSubject<number>(0);

    private listObjectsBS$ = new BehaviorSubject<GridObject[]>([]);
    currentSortDirection: string;

    private state: State = {
        page: 1,
        pageSize: 20,
        startNum: 0,
        endNum: 0,
        sortColumn: "",
        sortDirection: "desc",
        searchTerm: ""
    };

    searchTermArray: string[];
    thumbnailRefreshSeconds = 8;

    @HostListener("window:resize", [])
    private onResize() {
        this.prepGridLayout();
    }

    private tourSteps = TourSteps.gridDetail;
    private tourStepsThumb = TourSteps.gridDetailThumb;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private gs: GridsService,
        private us: UsersService,
        private sharedService: SharedService,
        private statePipe: StatePipe,
        private translate: TranslateService,
        private titleService: TitleService,
        private excelService: ExportExcelService,
        private stp: StatusTextPipe,
        private decimalPipe: DecimalPipe,
        public tourService: TourService,
        private versionPipe: VersionPipe,
        public mixpanelService: MixpanelService,
        private location: Location,
        private ngbModal: NgbModal,
        private ts: TargetsService,
        private ss: SourcesService
    ) {
        //
        this.route.paramMap.subscribe(params => {
            this.id = parseInt(params.get("id"), 10);

            if (this.id) this.grid = this.gs.getCachedGrid(this.id);

            // Loaded Details?
            if (!this.grid || !this.grid.hasFullDetails) this.loadingDetails = true;
            this.gs.getGrid(this.id);
        });
    }

    async ngOnInit() {
        this.us
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.user = user;
            });

        this.gridSubscription = this.gs.grids.subscribe(async grids => {
            this.grid = grids.find((g: Grid) => g.id === this.id);
            // Set Title
            this.titleService.setTitle("GRID", "", this.grid.name);
            //
            if (this.grid && this.grid.hasFullDetails) {
                // First Load
                if (this.firstLoad === true) {
                    this.firstLoad = false;
                    // Cycle Interval
                    if (!this.grid.cycle_pagination_interval || this.grid.cycle_pagination_interval === 0)
                        this.cyclePaginationInterval = 10;
                    else this.cyclePaginationInterval = this.grid.cycle_pagination_interval;
                    // Cycle Pagination
                    if (!this.grid.cycle_pagination) this.cyclePagination = false;
                    else {
                        this.cyclePagination = this.grid.cycle_pagination;
                        this.startGridCycle(this.cyclePaginationInterval);
                    }
                    // Settings
                    if (this.grid.fit_screen) this.fitToScreen = true;
                    if (this.grid.display_overlay) this.displayAlerts = true;
                    if (this.grid.display_audio) this.displayAudio = true;
                    if (this.grid.display_time) this.displayTime = true;
                    if (this.grid.show_info_btm) this.showInfoBtm = true;
                    if (this.grid.thumbnails_refresh_interval)
                        this.thumbnailRefreshSeconds = this.grid.thumbnails_refresh_interval / 1000;

                    if (this.grid?.thumbnails) this.tourService.initialize(this.tourStepsThumb);
                    else this.tourService.initialize(this.tourSteps);

                    this.viewOption = localStorage.getItem("gridDetailViewOption" + this.grid.id)
                        ? localStorage.getItem("gridDetailViewOption" + this.grid.id)
                        : "grid";
                }

                this.loadingDetails = false;

                window.setTimeout(() => {
                    this.orderBy();
                    this.prepGridLayout();
                    this.prepGridData();
                }, 0);
            }
        });

        // Start Grid Auto Refresh
        this.startGridRefresh();
    }

    ngOnDestroy() {
        this.gridSubscription.unsubscribe();
        this.stopGridRefresh();
        if (this.gridCycleSubscription) this.stopGridCycle();
    }

    resetSearch() {
        this.searchTerm = "";
    }

    toggleTopBar() {
        this.showTopBar = !this.showTopBar;
    }

    toggleBottomBar() {
        this.showBottomBar = !this.showBottomBar;
    }

    startGridRefresh() {
        this.gridRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopGridRefresh() {
        this.gridRefreshSubscription.unsubscribe();
    }

    startGridCycle(seconds: number) {
        this.gridCycleSubscription = interval(seconds * 1000).subscribe(() => {
            this.page++;
            if (this.page > Math.ceil(this.filteredObjects.length / this.pageSize)) this.page = 1;
        });
    }

    stopGridCycle() {
        this.gridCycleSubscription.unsubscribe();
    }

    toggleGridCycle(seconds: number) {
        if (this.cyclePagination) {
            this.stopGridCycle();
            this.cyclePagination = false;
        } else {
            this.cyclePagination = true;
            this.startGridCycle(seconds);
        }
    }

    intervalChange() {
        if (this.gridCycleSubscription) this.stopGridCycle();
        if (this.cyclePagination) this.startGridCycle(this.cyclePaginationInterval);
    }

    prepGridLayout() {
        if (!this.grid) return;

        // calcTileHeight
        this.calcTileHeight();

        // Set Width
        let width: number;
        width = this.gridContent.nativeElement.offsetWidth;

        // Set Height
        let height: number;
        height = this.gridContent.nativeElement.offsetHeight;

        // Col
        if (this.grid.cols != null && this.grid.cols != null) {
            this.gridCols = this.grid.cols;
        } else {
            if (width >= 1600) this.gridCols = 6;
            else if (width >= 1440 && width < 1600) this.gridCols = 5;
            else if (width >= 1200 && width < 1440) this.gridCols = 4;
            else if (width >= 992 && width < 1200) this.gridCols = 3;
            else if (width > 576 && width < 992) this.gridCols = 2;
            else this.gridCols = 1;
        }

        // Row
        if (this.grid.rows != null && this.grid.rows != null) {
            this.gridRows = this.grid.rows;
            this.pageSize = this.gridRows * this.gridCols;
        } else {
            this.gridHeight = height;
            this.gridRows = Math.floor(Math.max(this.gridHeight / this.tileHeight, 1));
            this.pageSize = this.gridRows * this.gridCols;
        }
    }

    calcTileHeight() {
        let commonHeight = 40;
        let feederExtras = 0;
        let broadcasterExtras = 0;
        let receiverExtras = 0;
        let sourceExtras = 0;
        let targetExtras = 20;
        let zecExtras = 0;
        const clusterExtras = 0;
        const channelExtras = 0;
        const thumbnailHeight = 200;

        if (this.grid.display_detailed_status) commonHeight += 20;
        if (this.grid.display_bitrate) {
            sourceExtras += 20;
            targetExtras += 20;
        }
        if (this.grid.display_tr101) sourceExtras += 20;
        if (this.grid.display_latency) sourceExtras += 20;
        if (this.grid.display_ip) {
            feederExtras += 20;
            broadcasterExtras += 20;
            receiverExtras += 20;
            targetExtras += 20;
            zecExtras += 20;
        }
        if (this.grid.display_cpu) {
            feederExtras += 20;
            broadcasterExtras += 20;
            receiverExtras += 20;
            zecExtras += 20;
        }
        if (this.grid.display_ram) {
            feederExtras += 20;
            broadcasterExtras += 20;
            zecExtras += 20;
        }
        if (this.grid.display_version) {
            feederExtras += 20;
            broadcasterExtras += 20;
            receiverExtras += 20;
            zecExtras += 20;
        }
        if (this.grid.display_bandwidth) {
            broadcasterExtras += 40;
            zecExtras += 40;
        }

        const feederMaxHeight = !this.grid.feeders ? 0 : commonHeight + feederExtras;
        const clusterMaxHeight = !this.grid.clusters ? 0 : commonHeight + clusterExtras;
        const broadcasterMaxHeight = !this.grid.broadcasters ? 0 : commonHeight + broadcasterExtras;
        const receiverMaxHeight = !this.grid.receivers ? 0 : commonHeight + receiverExtras;
        const sourceMaxHeight = !this.grid.sources ? 0 : commonHeight + sourceExtras;
        const channelMaxHeight = !this.grid.channels ? 0 : commonHeight + channelExtras;
        const targetMaxHeight = !this.grid.targets ? 0 : commonHeight + targetExtras;
        const thumbnailMaxHeight = !this.grid.thumbnails ? 0 : thumbnailHeight;
        const zecMaxHeight = !this.grid.zecs ? 0 : commonHeight + zecExtras;

        this.tileHeight = Math.max(
            feederMaxHeight,
            clusterMaxHeight,
            receiverMaxHeight,
            sourceMaxHeight,
            channelMaxHeight,
            targetMaxHeight,
            broadcasterMaxHeight,
            thumbnailMaxHeight,
            zecMaxHeight
        );
    }

    canEditGrid(grid: Grid) {
        if (!this.user || !this.grid) return false;
        return ((this.user.is_admin || this.user.is_objects_manager) && grid.public) || this.user.id === grid.user_id;
    }

    editGrid(id: number): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid, id, "edit"]);
    }

    cloneGrid(id: number): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid, id, "clone"]);
    }

    back() {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_list]);
    }

    viewChange() {
        if (this.viewOption === "grid") {
            if (this.grid?.thumbnails) this.tourService.initialize(this.tourStepsThumb);
            else this.tourService.initialize(this.tourSteps);
        }
        localStorage.setItem("gridDetailViewOption" + this.grid.id, this.viewOption);
        window.setTimeout(() => {
            this.prepGridLayout();
        }, 0);
    }

    async refresh() {
        this.refreshing = true;
        if (this.viewOption === "list")
            await this.gs.refreshGrid(this.id, this.currentTableObjectIDs, true).toPromise();
        else await this.gs.refreshGrid(this.id, this.currentObjectIDs, true).toPromise();
        this.refreshing = false;
    }

    orderBy() {
        switch (this.grid.sort_by) {
            case "name":
                this.sortColumn = "name";
                break;
            case "status":
            default:
                this.sortColumn = "_sortData.sortableStatusDesc";
                break;
        }
    }

    expand() {
        this.isExpanded = true;
        window.setTimeout(() => {
            this.prepGridLayout();
        }, 0);
    }

    contract() {
        this.isExpanded = false;
        window.setTimeout(() => {
            this.prepGridLayout();
        }, 0);
    }

    clickObject(object: ZixiObject) {
        if (this.selectMode) {
            object.isSelected = !object.isSelected;
            if (object.isSelected) {
                const index = this.selectedObjects.findIndex(obj => obj.id === object.id && obj.type === object.type);
                if (index === -1) this.selectedObjects.push(object);
            } else {
                const indexToRemove = this.selectedObjects.findIndex(
                    obj => obj.id === object.id && obj.type === object.type
                );
                this.selectedObjects.splice(indexToRemove, 1);
            }
        }
    }

    get currentPageObjectsMatrix$() {
        return this.currentPageObjectsMatrixBS$.asObservable();
    }
    get total$() {
        return this.totalBS$.asObservable();
    }
    get page() {
        return this.state.page;
    }
    set page(page: number) {
        this._set({ page });
        this.refresh();
    }
    get pageSize() {
        return this.state.pageSize;
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    get startNum() {
        return this.state.startNum;
    }
    set startNum(startNum: number) {
        this._set({ startNum });
    }
    get endNum() {
        return this.state.endNum;
    }
    set endNum(endNum: number) {
        this._set({ endNum });
    }
    get searchTerm() {
        return this.state.searchTerm;
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
        this.searchTermArray = this.sharedService.createArrayFromBooleanSearch(searchTerm);
    }
    get sortColumn() {
        return this.state.sortColumn;
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
    }
    get sortDirection() {
        return this.state.sortDirection;
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepGridData();
    }

    private prepGridData() {
        const { sortColumn, sortDirection, pageSize, page, searchTerm } = this.state;

        // keep selected grid objects selected through refresh
        if (this.selectedObjects) {
            for (const object of this.selectedObjects) {
                const o = this.grid.objects.find(obj => obj.id === object.id && obj.type === object.type);
                o.isSelected = true;
            }
        }

        // sort
        let listofObjs = this.sharedService.sort(this.grid.objects, sortColumn, sortDirection);

        // filter
        listofObjs = listofObjs.filter(object => this.sharedService.matches(object, searchTerm, this.objectFilter));
        this.filteredObjects = listofObjs;
        const total = listofObjs.length;

        // paginate
        this.currentObjects = listofObjs.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        if (this.gridCols) {
            const currentPageObjectsMatrix = this.createGridMatrix(this.currentObjects, this.gridCols);
            this.currentPageObjectsMatrixBS$.next(currentPageObjectsMatrix);
        }

        // get current object IDs
        this.currentObjectIDs = _.reduce(
            this.currentObjects,
            (currentObjectIds, object) => {
                if (!currentObjectIds[object.type]) currentObjectIds[object.type] = [];
                currentObjectIds[object.type].push(object.id);
                return currentObjectIds;
            },
            {}
        );

        // page start & end
        this.state.startNum = page * pageSize - (pageSize - 1);
        this.state.endNum = Math.min(this.state.startNum + pageSize - 1, total);

        this.totalBS$.next(total);

        // Grid Table Data Prep
        this.listObjectsBS$.next([...this.grid.objects]);
    }

    get listObjects$() {
        return this.listObjectsBS$.asObservable();
    }

    createGridMatrix(data: ZixiObject[], columns: number): ZixiObject[][] {
        const grid: ZixiObject[][] = [];

        for (let i = 0; i < data.length; i += columns) {
            grid.push(data.slice(i, i + columns));
        }

        return grid;
    }

    // Identify items in dataCollection, it will not destroy and init again
    trackByFunction(index, item) {
        return item ? item.id : undefined;
    }

    objectFilter = (object: GridObject, term: string) => {
        if (this.grid.thumbnails) {
            return (
                // Name
                object.name.toLowerCase().includes(term.toLowerCase()) ||
                // Access Tags
                (object.resourceTags &&
                    Object.values(object.resourceTags).some(tag =>
                        tag.name.toLowerCase().includes(term.toLowerCase())
                    )) ||
                // Input Cluster DNS Prefix
                (object.inputCluster && object.inputCluster.dns_prefix
                    ? object.inputCluster.dns_prefix.toLowerCase().includes(term.toLowerCase())
                    : undefined)
            );
        } else {
            return (
                // Name
                object.name.toLowerCase().includes(term.toLowerCase()) ||
                // Access Tags
                (object.resourceTags &&
                    Object.values(object.resourceTags).some(tag =>
                        tag.name.toLowerCase().includes(term.toLowerCase())
                    )) ||
                // Cluster
                (object.cluster ? object.cluster.toLowerCase().includes(term.toLowerCase()) : undefined) ||
                // Broadcaster Version
                (object.status?.version ? object.status.version.includes(term) : undefined) ||
                // Input Cluster DNS Prefix
                (object.inputCluster && object.inputCluster.dns_prefix
                    ? object.inputCluster.dns_prefix.toLowerCase().includes(term.toLowerCase())
                    : undefined) ||
                // Processing Cluster Name
                (object.processingCluster && object.processingCluster.name
                    ? object.processingCluster.name.toLowerCase().includes(term.toLowerCase())
                    : undefined) ||
                // MediaConnect Flow Name
                (object.mediaconnectFlow && object.mediaconnectFlow.name
                    ? object.mediaconnectFlow.name.toLowerCase().includes(term.toLowerCase())
                    : undefined) ||
                // Error messages
                (object.activeStates || []).find(as => as.message.toLowerCase().includes(term.toLowerCase()))
            );
        }
    };

    exportToExcel() {
        // Columns headers
        const defaultColumns = ["name", "processing_cluster", "type", "status", "error", "message", "access_tags"];
        const optionalColumns = [
            "bitrate_kbps",
            "tr101",
            "latency",
            "ip",
            "cpu_%",
            "ram_%",
            "in_bitrate_kbps",
            "out_bitrate_kbps",
            "version"
        ];
        const visibleColumns = defaultColumns.concat(optionalColumns);

        const headersForExcel = [];
        visibleColumns.forEach(column => {
            headersForExcel.push(this.translate.instant(column.toUpperCase()));
        });

        // Column data
        const dataForExcel = [];
        this.filteredObjects.forEach((object: GridObject) => {
            const values = [];
            // Name
            values.push(object.name);
            // Cluster Name
            let clusterName = "";
            // Input Cluster Name
            if (object.inputCluster && object.inputCluster.dns_prefix) clusterName = object.inputCluster.dns_prefix;
            // Processing Cluster Name
            if (object.processingCluster && object.processingCluster.name) clusterName = object.processingCluster.name;
            // MediaConnect Flow Name
            if (object.mediaconnectFlow && object.mediaconnectFlow.name) clusterName = object.mediaconnectFlow.name;
            values.push(clusterName);
            // Type
            values.push(this.translate.instant(object.type.toUpperCase()));
            // Status
            values.push(this.translate.instant(this.stp.transform(object)));
            // Error
            values.push(object.activeStates?.map(as => as.short_message)?.join(","));
            // Message
            values.push(object.activeStates?.map(as => as.message)?.join(","));
            // Access Tags
            values.push(_.map(object.resourceTags, "name").toString());
            // Bitrate
            values.push(object.status?.bitrate);
            // TR101
            let tr101 = "";
            if (object.status && object.status?.tr101 && object.status?.tr101?.status) {
                if (object.status.tr101.status.p1_ok) tr101 += "P1(Ok) ";
                else tr101 += "P1(Error) ";
                if (object.status.tr101.status.p2_ok) tr101 += "P2(Ok)";
                else tr101 += "P2(Error)";
            }
            values.push(tr101);
            // Latency
            values.push(object.latency);
            // IP
            values.push(object.status?.source_ip);
            // CPU
            values.push(object.status?.cpu);
            // RAM
            values.push(object.status?.ram);
            // Bandwidth
            values.push(object.status?.input_kbps);
            values.push(object.status?.output_kbps);
            // Version
            values.push(object.status?.about?.version);
            dataForExcel.push(values);
        });

        this.excelService.exportGridExcel(
            dataForExcel,
            headersForExcel,
            this.translate.instant("GRID"),
            this.grid.name,
            this.searchTerm
        );
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    onPagination(objects: Array<GridObject>) {
        this.currentTableObjectIDs = _.reduce(
            objects,
            (currentObjectIds, object) => {
                if (!currentObjectIds[object.type]) currentObjectIds[object.type] = [];
                currentObjectIds[object.type].push(object.id);
                return currentObjectIds;
            },
            {}
        );
        this.refresh();
    }

    getTR101Export(object: GridObject) {
        let tr101 = "";
        if (object.status && object.status?.tr101 && object.status?.tr101?.status) {
            if (object.status.tr101.status.p1_ok) tr101 += "P1(Ok) ";
            else tr101 += "P1(Error) ";
            if (object.status.tr101.status.p2_ok) tr101 += "P2(Ok)";
            else tr101 += "P2(Error)";
        }
        return tr101;
    }

    tableColumnsSchema: TableSchema<KeyMap<GridObject>>[] = [
        // Object
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            component: GridObjectsColumnComponent,
            assignComponentsInputs: assignComponentsGridObjectsAdapter,
            sortBy: row => row.name,
            textValue: row => row.name,
            valueToExport: (row: KeyMap<GridObject>) => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        // Status
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({ showOtherIcons: true }),
            textValue: row => this.translate.instant(this.stp.transform(row)),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? row._sortData.sortableStatusAsc
                    : row._sortData.sortableStatusDesc,
            valueToExport: row => this.translate.instant(this.stp.transform(row)),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: row => this.translate.instant(this.stp.simpleTransform(row)),
            columnSelectOptions: ["Ok", "Warning", "Error", "Other"]
        },
        // Error
        {
            header: this.translate.instant("ERROR"),
            columnDef: "error",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<GridObject>>(
                row => row.activeStates?.map(as => as.short_message)?.join(",") || "-",
                row => row.activeStates?.map(as => as.short_message)?.join(",") || "-",
                () => true
            ),
            valueToExport: row => row.activeStates?.map(as => as.short_message)?.join(","),
            sortBy: row => row.activeStates?.map(as => as.short_message)?.join(","),
            textValue: row => row.activeStates?.map(as => as.short_message)?.join(","),
            columnFilterType: ColumnFilterType.STRING
        },
        // Message
        {
            header: this.translate.instant("MESSAGE"),
            columnDef: "message",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<GridObject>>(
                row => row.activeStates?.map(as => as.message)?.join(",") || "-",
                row => row.activeStates?.map(as => as.message)?.join(",") || "-",
                () => true
            ),
            valueToExport: row => row.activeStates?.map(as => as.message)?.join(","),
            sortBy: row => row.activeStates?.map(as => as.message)?.join(","),
            textValue: row => row.activeStates?.map(as => as.message)?.join(","),
            columnFilterType: ColumnFilterType.STRING
        },
        // Type
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<GridObject>>(
                row => this.sharedService.objType(row.type),
                row => this.sharedService.objType(row.type),
                () => true
            ),
            sortBy: row => this.sharedService.objType(row.type),
            textValue: row => this.sharedService.objType(row.type),
            valueToExport: row => this.sharedService.objType(row.type),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: row => this.sharedService.objType(row.type),
            columnSelectOptions: [
                "Broadcaster",
                "Broadcaster Cluster",
                "Channel",
                "Feeder",
                "Receiver",
                "Source",
                "Target",
                "ZEC"
            ]
        },
        // Target Channel
        {
            header: this.translate.instant("CHANNEL"),
            columnDef: "channel",
            width: 160,
            visible: false,
            component: GridChannelColumnComponent,
            assignComponentsInputs: assignComponentsGridChannelAdapter,
            sortBy: row =>
                this.sharedService.isTarget(row) && (row.adaptiveChannel || row.deliveryChannel || row.mediaconnectFlow)
                    ? row.adaptiveChannel?.name || row.deliveryChannel?.name || row.mediaconnectFlow?.name
                    : "",
            textValue: row =>
                this.sharedService.isTarget(row) && (row.adaptiveChannel || row.deliveryChannel || row.mediaconnectFlow)
                    ? row.adaptiveChannel?.name || row.deliveryChannel?.name || row.mediaconnectFlow?.name
                    : "",
            valueToExport: row =>
                this.sharedService.isTarget(row) && (row.adaptiveChannel || row.deliveryChannel || row.mediaconnectFlow)
                    ? row.adaptiveChannel?.name || row.deliveryChannel?.name || row.mediaconnectFlow?.name
                    : "",
            columnFilterType: ColumnFilterType.STRING
        },
        // Cluster
        {
            header: this.translate.instant("CLUSTER"),
            columnDef: "cluster",
            width: 160,
            visible: true,
            component: GridClusterColumnComponent,
            assignComponentsInputs: assignComponentsGridClusterAdapter,
            sortBy: row =>
                row.inputCluster?.dns_prefix || row.processingCluster?.name || row.mediaconnectFlow?.name || "",
            textValue: row =>
                row.inputCluster?.dns_prefix || row.processingCluster?.name || row.mediaconnectFlow?.name || "",
            valueToExport: row =>
                row.inputCluster?.dns_prefix || row.processingCluster?.name || row.mediaconnectFlow?.name || "",
            columnFilterType: ColumnFilterType.STRING
        },
        // grid.display_bitrate
        {
            header: this.translate.instant("BITRATE"),
            columnDef: "bitrate",
            align: "right",
            width: 100,
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<GridObject>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number:
                        ["source", "mediaconnect_sources"].includes(row.type) && row.status?.bitrate
                            ? this.decimalPipe.transform(row.status.bitrate, "1.0-0")
                            : this.sharedService.isTarget(row) && row.status?.stats?.net_bitrate
                            ? this.decimalPipe.transform(row.status?.stats?.net_bitrate / 1000, "1.0-0")
                            : "-",
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            sortBy: row =>
                ["source", "mediaconnect_sources"].includes(row.type) && row.status?.bitrate
                    ? row.status.bitrate
                    : this.sharedService.isTarget(row) && row.status?.stats?.net_bitrate
                    ? row.status?.stats?.net_bitrate / 1000
                    : "0",
            textValue: row =>
                ["source", "mediaconnect_sources"].includes(row.type) && row.status?.bitrate
                    ? this.decimalPipe.transform(row.status.bitrate, "1.0-0")
                    : this.sharedService.isTarget(row) && row.status?.stats?.net_bitrate
                    ? this.decimalPipe.transform(row.status?.stats?.net_bitrate / 1000, "1.0-0")
                    : "",
            valueToExport: row =>
                ["source", "mediaconnect_sources"].includes(row.type) && row.status?.bitrate
                    ? this.decimalPipe.transform(row.status.bitrate, "1.0-0")
                    : this.sharedService.isTarget(row) && row.status?.stats?.net_bitrate
                    ? this.decimalPipe.transform(row.status?.stats?.net_bitrate / 1000, "1.0-0")
                    : "",
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: row =>
                ["source", "mediaconnect_sources"].includes(row.type) && row.status?.bitrate
                    ? this.decimalPipe.transform(row.status.bitrate, "1.0-0")
                    : this.sharedService.isTarget(row) && row.status?.stats?.net_bitrate
                    ? this.decimalPipe.transform(row.status?.stats?.net_bitrate / 1000, "1.0-0")
                    : ""
        },
        // grid.display_tr101
        {
            header: this.translate.instant("TR101"),
            columnDef: "tr101",
            width: 100,
            visible: true,
            component: ZxTr101ColSourceComponent,
            assignComponentsInputs: (
                tr101ComponentRef: ComponentRef<ZxTr101ColSourceComponent>,
                row: KeyMap<GridObject>
            ) => {
                const trCompRef = tr101ComponentRef.instance;
                trCompRef.source = ["source", "mediaconnect_sources"].includes(row.type)
                    ? (row as MediaConnectSource | Source)
                    : null;

                trCompRef.ngIf = ["source", "mediaconnect_sources"].includes(row.type);
            },
            sortBy: row =>
                row.status?.tr101?.status?.p1_ok && row.status?.tr101?.status?.p2_ok
                    ? 0
                    : row.status?.tr101?.status?.p1_ok
                    ? 1
                    : 2,
            valueToExport: row => this.getTR101Export(row)
        },
        // grid.display_latency
        {
            header: this.translate.instant("LATENCY"),
            columnDef: "latency",
            width: 100,
            visible: true,
            align: "right",
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<GridObject>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: row.latency || "-",
                    unit: "ms",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            sortBy: row => row.latency || "",
            textValue: row => row.latency || "",
            valueToExport: row => row.latency || "",
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: row => row.latency || ""
        },
        // grid.display_ip
        {
            header: this.translate.instant("IP"),
            columnDef: "ip",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<GridObject>>(
                row =>
                    ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                        ? row.status?.source_ip || "-"
                        : this.sharedService.isTarget(row)
                        ? row.status?.source_ip || "-"
                        : "-",
                row =>
                    ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                        ? row.status?.source_ip || "-"
                        : this.sharedService.isTarget(row)
                        ? row.status?.source_ip || "-"
                        : "-",
                () => true
            ),
            sortBy: row =>
                ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                    ? row.status?.source_ip || ""
                    : this.sharedService.isTarget(row)
                    ? row.status?.source_ip || ""
                    : "",
            textValue: row =>
                ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                    ? row.status?.source_ip || ""
                    : this.sharedService.isTarget(row)
                    ? row.status?.source_ip || ""
                    : "",
            valueToExport: row =>
                ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                    ? row.status?.source_ip || ""
                    : this.sharedService.isTarget(row)
                    ? row.status?.source_ip || ""
                    : "",
            columnFilterType: ColumnFilterType.STRING
        },
        // grid.display_cpu
        {
            header: this.translate.instant("CPU"),
            columnDef: "cpu",
            width: 80,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<GridObject>,
                searchTerm: string[]
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.cpu, "1.0-2") ?? "-";
                compRef.unit = "%";
                compRef.searchTerm = searchTerm.toString();
            },
            textValue: row => row.status?.cpu ?? "",
            sortBy: row => row.status?.cpu ?? "0",
            bgValue: row => row.status?.cpu ?? null,
            valueToExport: row => row.status?.cpu,
            columnFilterType: ColumnFilterType.NUMBER
        },
        // grid.display_ram
        // object.type === 'broadcaster' || object.type === 'feeder'
        {
            header: this.translate.instant("RAM"),
            columnDef: "ram",
            width: 80,
            align: "right",
            visible: true,

            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<GridObject>,
                searchTerm: string[]
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.ram, "1.0-2") ?? "-";
                compRef.unit = "%";
                compRef.searchTerm = searchTerm.toString();
            },
            textValue: row => row.status?.ram ?? "",
            sortBy: row => row.status?.ram ?? "0",
            bgValue: row => row.status?.ram ?? null,
            valueToExport: row => row.status?.ram,
            columnFilterType: ColumnFilterType.NUMBER
        },
        // grid.display_bandwidth
        {
            header: this.translate.instant("IN_BITRATE"),
            columnDef: "in_bitrate",
            width: 100,
            visible: false,
            align: "right",
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<GridObject>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number:
                        ["broadcaster", "zec"].includes(row.type) && row.status?.input_kbps
                            ? this.decimalPipe.transform(row.status.input_kbps, "1.0-0")
                            : "-",
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            sortBy: row =>
                ["broadcaster", "zec"].includes(row.type) && row.status?.input_kbps
                    ? this.decimalPipe.transform(row.status.input_kbps, "1.0-0")
                    : "",
            textValue: row =>
                ["broadcaster", "zec"].includes(row.type) && row.status?.input_kbps
                    ? this.decimalPipe.transform(row.status.input_kbps, "1.0-0")
                    : "",
            valueToExport: row =>
                ["broadcaster", "zec"].includes(row.type) && row.status?.input_kbps
                    ? this.decimalPipe.transform(row.status.input_kbps, "1.0-0")
                    : "",
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: row =>
                ["broadcaster", "zec"].includes(row.type) && row.status?.input_kbps
                    ? this.decimalPipe.transform(row.status.input_kbps, "1.0-0")
                    : ""
        },
        // grid.display_bandwidth
        {
            header: this.translate.instant("OUT_BITRATE"),
            columnDef: "out_bitrate",
            width: 100,
            visible: false,
            align: "right",
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<GridObject>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number:
                        ["broadcaster", "zec"].includes(row.type) && row.status?.output_kbps
                            ? this.decimalPipe.transform(row.status.output_kbps, "1.0-0")
                            : "-",
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            sortBy: row =>
                ["broadcaster", "zec"].includes(row.type) && row.status?.output_kbps
                    ? this.decimalPipe.transform(row.status.output_kbps, "1.0-0")
                    : "",
            textValue: row =>
                ["broadcaster", "zec"].includes(row.type) && row.status?.output_kbps
                    ? this.decimalPipe.transform(row.status.output_kbps, "1.0-0")
                    : "",
            valueToExport: row =>
                ["broadcaster", "zec"].includes(row.type) && row.status?.output_kbps
                    ? this.decimalPipe.transform(row.status.output_kbps, "1.0-0")
                    : "",
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: row =>
                ["broadcaster", "zec"].includes(row.type) && row.status?.output_kbps
                    ? this.decimalPipe.transform(row.status.output_kbps, "1.0-0")
                    : ""
        },
        // grid.display_version
        {
            header: this.translate.instant("VERSION"),
            columnDef: "version",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<GridObject>>(
                row =>
                    ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                        ? this.versionPipe.transform(row.status?.about?.version || "-")
                        : "-",
                row =>
                    ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                        ? this.versionPipe.transform(row.status?.about?.version || "-")
                        : "-",
                () => true
            ),
            sortBy: row =>
                ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                    ? this.versionPipe.transform(row.status?.about?.version || "")
                    : "",
            textValue: row =>
                ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                    ? this.versionPipe.transform(row.status?.about?.version || "")
                    : "",
            valueToExport: row =>
                ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                    ? this.versionPipe.transform(row.status?.about?.version || "")
                    : "",
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: row =>
                ["receiver", "broadcaster", "feeder", "zec"].includes(row.type)
                    ? this.versionPipe.transform(row.status?.about?.version || "")
                    : ""
        },
        {
            header: this.translate.instant("TAGS"),
            columnDef: "access_tags",
            width: 160,
            visible: false,
            component: ZxTagsListComponent,
            assignComponentsInputs: (
                gridComponentRef: ComponentRef<ZxTagsListComponent>,
                row: KeyMap<GridObject>,
                searchTerm: string[]
            ) => {
                const gridCompRef = gridComponentRef.instance;
                gridCompRef.model = row;
                gridCompRef.searchTerm = searchTerm.toString();
            },
            textValue: row => row.resourceTags?.map(t => t.name).join(", ") ?? "",
            sortBy: row => row.resourceTags?.map(t => t.name).join("") ?? "",
            valueToExport: row => row.resourceTags?.map(t => t.name).join(", ") ?? "",
            columnFilterType: ColumnFilterType.TAGS,
            columnFilterValue: row => row.resourceTags?.map(t => t.id)
        }
    ];

    schemaThumbnail: TableSchema<KeyMap<GridObject>>[] = [
        {
            header: this.translate.instant("THUMBNAIL"),
            columnDef: "thumbnails",
            width: 110,
            visible: true,
            sticky: 1,
            component: ThumbnailWrapperComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ThumbnailWrapperComponent>,
                row: KeyMap<GridObject>
            ) => {
                componentRef.instance.row = row;
                componentRef.instance.thumbnailRefreshSeconds = this.thumbnailRefreshSeconds;
                componentRef.instance.fullyLoaded = this.fullyLoaded;
            }
        }
    ];

    tableColumnsSchemaThumbnails = [...this.schemaThumbnail, ...this.tableColumnsSchema];

    thumbnailRefreshChanged() {
        this.grid.thumbnails_refresh_interval = this.thumbnailRefreshSeconds * 1000;
    }

    toggleDR() {
        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        const objects =
            this.selectedObjects.length > this.selectedRows.length ? this.selectedObjects : this.selectedRows;
        modal.componentInstance.objects = objects.map(o => ({
            id: o.id,
            type: this.sharedService.objType(o.type) === "Target" ? this.ts.getTargetApiType(o) : o.type
        }));
    }
}
